import { add, format, lastDayOfMonth } from 'date-fns';

type Props = {
  initialDate?: Date;
  duration?: Duration;
  lastDay?: boolean;
};

// Server expected date format YYYY-MM-DD
export const formatAvailabilityDate = ({
  initialDate = new Date(),
  duration,
  lastDay = false,
}: Props) => {
  let date: Date;

  if (duration) {
    date = add(initialDate, duration);
  } else {
    date = initialDate;
  }

  if (lastDay) {
    return format(lastDayOfMonth(date), 'yyyy-MM-dd');
  }

  return format(date, 'yyyy-MM-dd');
};
